import { NewAlphaIcon } from '@alpha/components'
import { Anchor } from '@sergeimeza/uikit-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { copyrightYear } from '~/utils/constants'

const AlphaLogo = () => (
  <div className="flex items-center justify-center">{NewAlphaIcon}</div>
)

const ISMSLogo = () => <img className="w-20" src="PT_ISMS.jpg" alt="isms" />

const SignInLayoutComponent: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {children}

      <div className="flex flex-col content-center justify-end">
        <div className="flex flex-col items-center">
          <span className="font-medium text-gray-700">
            【{t('問い合わせ')}】
          </span>
          <span className="font-medium text-gray-700">
            alpha@pestalozzi-tech.com
          </span>
          <span className="font-medium text-gray-700">
            {t('担当：Alpha新体力テストの測定・集計システム担当')}
          </span>
          <p className="font-medium text-gray-700">
            {t('続行することで、Pestalozzi Technology株式会社の')}
            <Anchor
              href="https://pestalozzi-tech.com/alpha-terms/"
              className="text-primary-700 underline font-semibold "
            >
              {t('サービス利用規約')}
            </Anchor>
            {t('および、')}
            <Anchor
              href="https://pestalozzi-tech.com/privacy-policy/"
              className="text-blue-700 underline font-semibold"
            >
              <span className="px-1">{t('個人情報保護ポリシー')}</span>
            </Anchor>
            {t('に同意します。')}
          </p>

          <ISMSLogo />
        </div>

        <div className="flex flex-col justify-center mx-auto mt-1 text-center-f font-medium text-gray-700">
          <span>
            {t('Pestalozzi Technology株式会社にて認証取得')}
            <br />
            {t('スポーツ分野におけるアプリケーションの設計・開発')} <br />
            {t('・販売及びサービス提供において認証取得')}
          </span>
        </div>
        <div className="mt-3">
          <p className="text-gray-700 text-center text-xs">
            Copyright © Pestalozzi Technology {copyrightYear}
          </p>
        </div>
      </div>
    </>
  )
}

export const SignInLayout = Object.assign(SignInLayoutComponent, {
  AlphaLogo,
  ISMSLogo,
})
